import React from 'react'
import Link from 'next/link'

import Button from 'components/Button'

export default function ButtonLink({ children, target, href, ...props }) {
  return (
    <Link href={href} passHref legacyBehavior>
      <Button as="a" target={target} {...props}>
        {children}
      </Button>
    </Link>
  )
}
