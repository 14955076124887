import { createSlice } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

const initialState = {
  subscriptionState: false,
  isSubscribeModalActive: false,
  isUnsubscribeModalActive: false,
  plan: null,
  discount: null,
  promocode: null,
}

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setSubscriptionState(state, action) {
      state.subscriptionState = action.payload
    },
    setSubscribeModalActive(state, action) {
      state.isSubscribeModalActive = action.payload
    },
    setUnsubscribeModalActive(state, action) {
      state.isUnsubscribeModalActive = action.payload
    },
    setPlan(state, action) {
      state.plan = action.payload
    },
    setDiscount(state, action) {
      state.discount = action.payload
    },
    setPromocode(state, action) {
      state.promocode = action.payload
    },
  },

  // Special reducer for hydrating the state. Special case for next-redux-wrapper
  extraReducers: {
    [HYDRATE]: (state, action) => ({
      ...state,
      ...action.payload.subscription,
    }),
  },
})

export const {
  setSubscriptionState,
  setSubscribeModalActive,
  setUnsubscribeModalActive,
  setPlan,
  setDiscount,
  setPromocode,
} = subscriptionSlice.actions

export const selectSubscriptionState = (state) =>
  state.subscription.subscriptionState

export default subscriptionSlice.reducer
