import { configureStore } from '@reduxjs/toolkit'
import { createWrapper } from 'next-redux-wrapper'
import { subscriptionSlice } from 'store/subscription.slice'

const makeStore = () =>
  configureStore({
    reducer: {
      [subscriptionSlice.name]: subscriptionSlice.reducer,
    },
    devTools: true,
  })

export const wrapper = createWrapper(makeStore)
