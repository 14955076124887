import React, { useEffect } from 'react'
import * as Sentry from '@sentry/nextjs'
import useUserProfile from 'hooks/useUserProfile'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { appWithTranslation, useTranslation } from 'next-i18next'
import { CookiesProvider } from 'react-cookie'
import { FirebaseAppProvider, useAuth } from 'reactfire'
import { wrapper } from 'store/store'

import SubscriptionModal from 'components/SubscriptionModal'

import 'firebase/auth'

import 'styles/globals.css'
import 'animate.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import 'react-tooltip/dist/react-tooltip.css'
import 'react-quill/dist/quill.snow.css'
import 'rc-slider/assets/index.css'

const firebaseConfig = {
  apiKey: 'AIzaSyCBmrYCks8OpCd68v9AyRJsVDnV3BqW0Sk',
  authDomain: 'anyquiz-151c3.firebaseapp.com',
  projectId: 'anyquiz-151c3',
  storageBucket: 'anyquiz-151c3.appspot.com',
  messagingSenderId: '651193863430',
  appId: '1:651193863430:web:ba022d5b8c1488305ee287',
  measurementId: 'G-4R4ETJPP85',
}

const Auth = () => {
  const auth = useAuth()
  const { data: userProfile } = useUserProfile()
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        global.dataLayer.push({ event: 'setUserId', userId: user.uid })
        Sentry.setUser({ id: user?.uid, email: user?.email })
      } else {
        global.dataLayer.push({ event: 'setUserId', userId: null })
        Sentry.setUser(null)
      }
    })
  }, [])

  useEffect(() => {
    global.dataLayer.push({
      event: 'setUserProperties',
      userProperties: {
        admin: userProfile?.admin,
        language: userProfile?.language,
        plan: userProfile?.plan?.id || 'free',
        subscription: userProfile?.plan?.hasActiveSubscription,
      },
    })
  }, [userProfile])

  return null
}

function MyApp({ Component, pageProps }) {
  const { t } = useTranslation()
  const router = useRouter()

  useEffect(() => {
    const { pathname, asPath, query = {}, locale } = router
    global.dataLayer.push({
      event: 'logEvent',
      eventType: 'Page Visited',
      eventProperties: { pathname, url: asPath, locale, ...query },
    })
  }, [router.asPath])

  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <Head>
        <title>{t('meta.title')}</title>
        <meta name="description" content={t('meta.description')} />
        <link rel="icon" href="/favicon.ico?v=6" />
      </Head>
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-TWSGQZZ');
      `}
      </Script>
      <Auth />
      <CookiesProvider>
        <Component {...pageProps} />
      </CookiesProvider>
      <SubscriptionModal />
    </FirebaseAppProvider>
  )
}

export default wrapper.withRedux(appWithTranslation(MyApp))
