import { useFirestore, useFirestoreDocData, useUser } from 'reactfire'

import 'firebase/auth'
import 'firebase/firestore'

const useUserProfile = (uid) => {
  const { data: user } = useUser()
  const firestore = useFirestore()
  const userId = uid || user?.uid
  const userProfileRef = firestore.collection('users').doc(userId)
  return {
    uid: userId,
    ...(useFirestoreDocData(userProfileRef, { idField: 'uid' }) || {}),
    ref: userProfileRef,
  }
}

export default useUserProfile
