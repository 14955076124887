import React from 'react'
import * as icons from '@heroicons/react/24/outline'
import classnames from 'classnames'

import Loader from 'components/Loader'

const Button = React.forwardRef(
  (
    {
      as = 'button',
      block,
      className,
      children,
      disabled,
      icon,
      loading,
      outline,
      size = 'base',
      type = 'button',
      variant = 'default',
      ...rest
    },
    ref,
  ) => {
    let variantClassNames = {
      default:
        'border bg-white text-gray-700 hover:bg-gray-100 bg-default text-main border-default border-default',
      primary:
        'border border-blue-600 bg-blue-600 text-white hover:bg-blue-700 hover:text-white bg-primary border-primary',
      warning:
        'border border-amber-600 bg-amber-600 text-white hover:bg-amber-700 hover:text-white bg-warning border-warning',
      danger:
        'border border-red-600 bg-red-600 text-white hover:bg-red-700 hover:text-white bg-danger border-danger',
      success:
        'border border-emerald-600 bg-emerald-600 text-white hover:bg-emerald-700 hover:text-white bg-success border-success',
    }[variant]

    if (outline) {
      variantClassNames = {
        default:
          'border border-gray-600 text-gray-600 hover:bg-gray-700 hover:text-white text-main border-main',
        primary:
          'border border-blue-600 text-blue-600 hover:bg-blue-700 hover:text-white text-primary',
        warning:
          'border border-amber-600 text-amber-600 hover:bg-amber-700 hover:text-white text-warning',
        danger:
          'border border-red-600 text-red-600 hover:bg-red-700 hover:text-white text-danger',
        success:
          'border border-emerald-600 text-emerald-600 hover:bg-emerald-700 hover:text-white text-success',
      }[variant]
    }

    const sizeClassNames = {
      xs: 'px-2 py-1 text-xs',
      sm: 'px-2 py-1 text-sm',
      base: 'px-4 py-2 text-base leading-snug',
      lg: 'px-6 py-3 text-lg',
      xl: 'px-8 py-4 text-xl',
      responsive: 'px-4 py-2 text-base md:px-6 md:py-3 md:text-lg',
    }[size]

    const blockClassNames = block
      ? 'block justify-center w-full text-center'
      : ''
    const disabledClassNames = disabled
      ? 'filter grayscale opacity-50 cursor-not-allowed'
      : ''
    const loadingClassNames = loading ? 'cursor-wait' : ''
    const baseClassNames =
      'font-medium flex items-center appearance-none rounded-md cursor-pointer transition-color duration-200'

    const classNames = classnames(
      className,
      baseClassNames,
      sizeClassNames,
      blockClassNames,
      variantClassNames,
      disabledClassNames,
      loadingClassNames,
    )

    let IconComponent
    if (icon) {
      IconComponent = icons[icon]
    }

    const Component = as

    return (
      <Component
        ref={ref}
        className={classNames}
        disabled={disabled || loading}
        type={type}
        {...rest}
      >
        {IconComponent && (
          <span>
            <IconComponent className={`w-5 h-5 ${children ? 'mr-2' : ''}`} />
          </span>
        )}
        {children}
        {loading && (
          <span className="ml-2">
            <Loader size={4} />
          </span>
        )}
      </Component>
    )
  },
)

Button.displayName = 'Button'

export default Button
