const getPlanPrice = (price, promocodeData) => {
  if (promocodeData) {
    return (
      Math.round((price - (price / 100) * promocodeData?.percent) / 1000) * 1000
    )
  }
  return price
}

export default getPlanPrice
